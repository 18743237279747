<template>
  <div class="content">
    <div class="edit" style="border: 1px solid #ccc;">
      <Toolbar :editor="editorRef" :defaultConfig="toolbarConfig" :mode="mode" style="border-bottom: 1px solid #ccc" />
      <Editor :defaultConfig="editorConfig" :mode="mode" v-model="valueHtml" style="height: 284px; overflow-y: hidden"
        @onCreated="handleCreated" @onChange="handleChange" @onDestroyed="handleDestroyed" @onFocus="handleFocus"
        @onBlur="handleBlur" @customAlert="customAlert" @customPaste="customPaste" />
    </div>
  </div>
</template>

<script>
import '@wangeditor/editor/dist/css/style.css';
import { onBeforeUnmount, ref, shallowRef, onMounted, nextTick, watch, onUnmounted } from 'vue';
import { Editor, Toolbar } from '@wangeditor/editor-for-vue';
import { DomEditor } from '@wangeditor/editor'
import { UPLOAD_BASE_URL } from '@/common/constant';
import emitter from '@/utils/eventbus';

export default {
  components: { Editor, Toolbar },
  emits: ['onHtmlChanged'],
  setup(props, { emit }) {
    // 编辑器实例，必须用 shallowRef，重要！
    const editorRef = shallowRef();

    // 内容 HTML
    const valueHtml = ref('');

    emitter.on('changeUpdateCardCardInfo', (info) => {
      valueHtml.value = info
    })


    const getFocus = () => {
      const editor = editorRef.value // 获取 editor ，必须等待它渲染完之后
      if (editor == null) return
      editor.focus()
    }

    // 模拟 ajax 异步获取内容
    onMounted(() => {
      const editor = editorRef.value // 获取 editor ，必须等待它渲染完之后
      if (editor == null) return
    });


    emitter.on('changeCurrentMdEditOnFocus', () => {
      // console.log('获取焦点');
      getFocus()
    })

    onUnmounted(() => {
      emitter.off('changeCurrentMdEditOnFocus')
    })

    const toolbarConfig = {
      excludeKeys: [
        "codeBlock",
        "fullScreen",
        'blockquote',
        'fontFamily',
        'group-video'
      ]
    }

    const editorConfig = { MENU_CONF: {}, placeholder: '请输入内容...' };
    editorConfig.MENU_CONF['uploadImage'] = {
      server: `${UPLOAD_BASE_URL}/manager/upload`,
      fieldName: 'file',
      maxFileSize: 10 * 1024 * 1024, // 1M
      timeout: 60 * 1000, // 60 秒
      customInsert(res, insertFn) {
        // res 即服务端的返回结果
        // 从 res 中找到 url alt href ，然后插图图片
        let url = res.data.fileName
        insertFn(url, '', '')
      },
      onBeforeUpload(file) {
        // file 选中的文件，格式如 { key: file }
        return file
        // 可以 return
        // 1. return file 或者 new 一个 file ，接下来将上传
        // 2. return false ，不上传这个 file
      },
      // 上传错误，或者触发 timeout 超时
      onError(file, err, res) {
        // console.log(`${file.name} 上传出错`, err, res)
      },
      // 继续写其他配置...

      //【注意】不需要修改的不用写，wangEditor 会去 merge 当前其他配置
    }

    editorConfig.MENU_CONF['uploadVideo'] = {
      server: `${UPLOAD_BASE_URL}/manager/upload`,
      fieldName: 'file',
      maxFileSize: 500 * 1024 * 1024, // 5M
      timeout: 120 * 1000, // 120 秒
      customInsert(res, insertFn) {
        // res 即服务端的返回结果
        // 从 res 中找到 url ，然后插入视频
        let url = res.data.fileName
        insertFn(url)
      },
    }

    // editorConfig.customPaste = (editor, event) => {
    //   // event 是 ClipboardEvent 类型，可以拿到粘贴的数据
    //   // 可参考 https://developer.mozilla.org/zh-CN/docs/Web/API/ClipboardEvent

    //   const html = event.clipboardData.getData('text/html') // 获取粘贴的 html
    //   // const text = event.clipboardData.getData('text/plain') // 获取粘贴的纯文本
    //   // const rtf = event.clipboardData.getData('text/rtf') // 获取 rtf 数据（如从 word wsp 复制粘贴）
    //   // console.log(html);
    //   // 同步
    //   editor.insertText('xxx123')

    //   // 异步


    //   // 阻止默认的粘贴行为
    //   // event.preventDefault()
    //   // return false

    //   // 继续执行默认的粘贴行为
    //   return true
    // }


    // 组件销毁时，也及时销毁编辑器，重要！
    onBeforeUnmount(() => {
      const editor = editorRef.value;
      if (editor == null) return;

      editor.destroy();
      emitter.off('changeUpdateCardCardInfo')
    });

    // 编辑器回调函数
    const handleCreated = (editor) => {
      editorRef.value = editor; // 记录 editor 实例，重要！
      editorRef.value.handleTab = () => {
        editor.insertText('    ');
      }

    };
    const handleChange = (editor) => {
      emit('onTextChanged', editor.getText())
      emit('onHtmlChanged', editor.getHtml())


    };
    const handleDestroyed = (editor) => {
    };
    const handleFocus = (editor) => {

    };
    const handleBlur = (editor) => {

    };
    const customAlert = (info, type) => {

    };
    const customPaste = (editor, event, callback) => {
      // // console.log('ClipboardEvent 粘贴事件对象', event);
      // const html = event.clipboardData.getData('text/html') // 获取粘贴的 html
      // const text = event.clipboardData.getData('text/plain') // 获取粘贴的纯文本
      // // 自定义插入内容
      // editor.insertText(text);

      // 返回值（注意，vue 事件的返回值，不能用 return）
      // callback(false); // 返回 false ，阻止默认粘贴行为
      callback(true) // 返回 true ，继续默认的粘贴行为
    };

    const insertText = () => {
      const editor = editorRef.value;
      if (editor == null) return;

      editor.insertText('hello world');
    };

    const printHtml = () => {
      const editor = editorRef.value;
      if (editor == null) return;
    };

    const disable = () => {
      const editor = editorRef.value;
      if (editor == null) return;
      editor.disable()
    };

    return {
      editorRef,
      mode: 'default',
      valueHtml,
      toolbarConfig,
      editorConfig,
      handleCreated,
      handleChange,
      handleDestroyed,
      handleFocus,
      handleBlur,
      customAlert,
      customPaste,
      insertText,
      printHtml,
      disable
    };
  },
};
</script>


<style scoped lang="less">
.content {
  display: flex;
  justify-content: space-around;
  width: 340px;
  margin: 0 auto;

  .edit {
    width: 100%;

    .w-e-textarea-video-container {
      width: 100%;
    }

    .w-e-textarea-video-container video {
      width: 96%;
    }

    &:deep(.w-e-textarea-video-container) {
      width: 100% !important;
    }
    &:deep(.w-e-image-container) {
      width: inherit !important;
    }
  }

  .view {
    width: 420px;


    textarea {
      height: 100%;
    }
  }
}
</style>
<style>
</style>
